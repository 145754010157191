<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="profile-switch" slot="component">
      <p class="profile-switch-pin-title">{{ $t("Enter PIN To Switch Profile") }}</p>
      <div class="profile-switch-pin">
        <form @submit.prevent="submitSwitchProfile">
          <div class="form-group">
            <div class="error-container" v-if="formError">
              <p class="error">{{ formError }}</p>
            </div>
            <div class="form-control profile-switch-pin-box">
              <label>{{ $t("Enter PIN") }}</label>
              <div dir="ltr" id="pininputs" class="profile-switch-pin-box-inputs">
                <input
                  v-for="(item, index) in numberOfOtpBlocks"
                  :key="index"
                  class="pin"
                  type="text"
                  v-model="userPin[item - 1]"
                  @input="grabPin"
                  maxlength="1"
                />
              </div>
            </div>
            <p @click="forgotPin()" class="profile-switch-pin-forgot">{{ $t("forgot PIN")}}</p>
          </div>
         
          <div>
            <button
              class="button-primary"
              :class="[providerUniqueId === 'z01x1wPo' ? 'primary-nammaflix-button' : 'primary-net5-button']"
            >
              <span class="btn-txt-color">{{ $t("Switch Profile") }}</span>
            </button>
          </div>
        </form>
      </div>

      <br />

      <div v-if="isLoading"><Loading /></div>
    </div>
  </Popup>
</template>

<script>
import Utility from "@/mixins/Utility.js";
import { _providerId } from "@/provider-config.js";
import { mapActions, mapGetters, mapMutations } from "vuex";

import snackbar from "node-snackbar";
import "../../../../node_modules/node-snackbar/dist/snackbar.css";
import { showSnackBar } from '../../../utilities';

export default {
  props: {
    closePopupEvent: {
      type: Function,
    },

    profile: {
      type: Object,
    },
  },

  data() {
    return {
      width: "30%",
      margin: "15% auto",
      radiusType: "full",
      userPin: [],
      profilePin: null,
      formError: null,
      providerUniqueId: _providerId,
      numberOfOtpBlocks: 4,
      isLoading: false,
    };
  },

  watch: {
    formError() {
      setTimeout(() => {
        this.formError = null;
      }, 2000);
    },
  },

  computed: {
    ...mapGetters(["getPlanDeeplinkPath", "subscriberid"])
  },

  mounted() {
    this.setupPin();
  },

  methods: {
    ...mapActions(["switchProfile", "ResetProfilePin", "fcmMessagingSubscribeToTopic"]),

    ...mapMutations(["setProfileid"]),

    setupPin() {
      setTimeout(() => {
        this.getId = document.getElementById("pininputs");
        this.pinFunctionality(this.getId);
      }, 1000);
    },

    grabPin() {
      if (this.userPin.length > 3) {
        let pin = "";
        this.userPin.forEach((element) => {
          pin += element;
        });
        this.profilePin = pin;
      }
    },

    submitSwitchProfile() {
      this.isLoading = true;

      if (!this.profilePin || (this.profilePin && this.profilePin.length < this.numberOfOtpBlocks)) {
        this.formError = this.$t("Please enter the Pin");
        this.isLoading = false;

        return;
      }

      const profilePayload = {
        profileid: this.profile.profileid,
        pin: {
          profilepin: this.profilePin,
        },
      };

      this.switchProfile(profilePayload)
        .then((response) => {
          if (!response.data.reason) {
            this.isLoading = false;
            localStorage.setItem("sessionToken", response.data.success);

            localStorage.setItem("currentProfileId", profilePayload.profileid);
						localStorage.setItem("currentProfileIdAdminCheck", profilePayload.profileid);
            localStorage.setItem("subscriberProfileDetails", JSON.stringify(this.profile));

            this.setProfileid(profilePayload.profileid);

            this.fcmMessagingSubscribeToTopic(this.subscriberid);
						// setTimeout(() => {
            //     this.$router.push({ name: "Home" });
            //  }, 600)
						// this.$router.push("/");

            if (sessionStorage.getItem("setPlanDeeplinkPath")) {
              this.$router.push(sessionStorage.getItem("setPlanDeeplinkPath"));
							sessionStorage.removeItem("setPlanDeeplinkPath")
            } else {
              this.$router.push({ name: "Home" });
            }

          } else {
            this.userPin = [];
            this.profilePin = "";

            showSnackBar(this.$t(response.data.reason));

            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },

    validateOtp(pin) {
      this.formError = null;
      if (!pin) {
        this.formError = "Please enter pin";
        return false;
      }
      return true;
    },

    closePopup() {
      console.log("CLOSE POPUP");

      this.$emit("closeProfilePinPopup");
    },

    forgotPin() {
       const profilePinPayload = {
        profileid: this.profile.profileid,
      };
      this.ResetProfilePin(profilePinPayload)
        .then((response) => {
          if (!response.data.reason) {
            this.isLoading = false;
            console.log("forgot pin"); 
            this.closePopup();
            this.$emit("switchSetPin");
          } else {
            showSnackBar(this.$t(response.data.reason));

            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.isLoading = false;
        });
    }
  },

  components: {
    Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
  },

  mixins: [Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";

.profile-switch {
  padding: 3.6rem;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  &-pin-title {
    color: $clr-light-gd4;
    font-size: 1.4rem;
    color: #c1c1c1;
    font-family: $font-regular;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 29px;
    margin-bottom: 1.2rem;
  }
  &-pin-box {
    padding-bottom: 20px;
    label {
      font-size: 0.8rem;
    }
    &-inputs {
      display: flex;
      align-items: center;
      .pin {
        width: 25%;
        margin-right: 5px;
        text-align: center;
      }
    }
  }
  &-pin-forgot {
    color: #F7F9FC;
    font-size: 14px;
    font-family:  $font-regular;
    margin: 10px auto;
    text-align: center;
    cursor: pointer;
  }
}

@media only screen and (max-width: 576px) {
  .profile-switch {
    &-pin-title {
      font-size: 0.9rem;
    }
  }
}
</style>
